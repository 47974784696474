<template>
	<div class="main index">
		<header>
			<ul class="tabs">
				<li
					:class="{ tab: true, active: v.route_name === $route.name }"
					v-for="(v, k) in tab"
					:key="k"
					@click="tabChange(v.route_name)"
				>
					{{ v.name }}
				</li>
			</ul>

			<!-- 操作 -->
			<div class="option">
				<el-popover
					placement="bottom"
					width="100"
					trigger="click"
					popper-class="select_address"
					:disabled="first_page_power.area > 0"
					v-if="show_area"
				>
					<div class="item" slot="reference">
						<span>{{ areaList.find((it) => it.value === area).label }}</span>
						<i class="el-icon-arrow-down"></i>
					</div>

					<ul class="address">
						<li
							v-for="it in areaList"
							:class="{ active: it.value === area }"
							@click="area = it.value"
						>
							{{ it.label }}
						</li>
					</ul>
				</el-popover>

				<el-date-picker
					size="mini"
					type="year"
					value-format="yyyy"
					:clearable="false"
					class="item"
					v-model="year"
				></el-date-picker>
			</div>
		</header>

		<div class="container">
			<router-view :area="area" :year="year" />
		</div>
	</div>
</template>

<script>
import globalYear from "@/mixins/global_year";

export default {
	mixins: [globalYear],
	data() {
		return {
			tab: [
				{
					name: "市项目总体情况",
					route_name: "index_all",
				},
				{
					name: "区及自筹项目情况",
					route_name: "index_unplanned",
				},
			],
			// 地区
			area: 0,
			areaList: [
				{ value: 0, label: "全部地区" },
				{ value: 1, label: "州本级" },
				{ value: 2, label: "玛沁县" },
				{ value: 5, label: "甘德县" },
				{ value: 6, label: "达日县" },
				{ value: 4, label: "班玛县" },
				{ value: 3, label: "久治县" },
				{ value: 7, label: "玛多县" },
			],
		};
	},
	computed: {
		first_page_power() {
			return this.$store.state.user.current_user.first_page_power;
		},
		show_area() {
			return this.$route.path == '/pc/index/all'
		}
	},
	methods: {
		tabChange(name) {
			if (name === this.$route.name) return;
			this.$router.replace({ name: name });
		},
	},
	created() {
		if (this.$route.query.area) {
			this.area = Number(this.$route.query.area);
		}
		if (this.first_page_power.area > 0) {
			this.area = this.first_page_power.area;
		}
	},
};
</script>

<style scoped lang="less">
.container {
	padding: 0 !important;
	overflow-y: overlay;
}

header {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ededee;

	.tabs {
		border-bottom: none!important;
	}

	.option {
		display: flex;

		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-right: 10px;
			background-color: #f2f2f6;
			height: 30px;
			width: 100px;
			border-radius: 4px;
			padding: 0 10px;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 13px;

			/deep/ input.el-input__inner {
				border: none;
				background: #f2f2f6;
				border-radius: 14px;
				padding-right: 0;
				padding-left: 20px;
			}
		}
	}
}
</style>

<style lang="less">
.select_address {
	padding: 0;
	overflow: hidden;
	min-width: 100px;
	padding: 4px;
	box-sizing: border-box;

	.address {
		display: flex;
		flex-direction: column;
		max-height: 200px;
		overflow: overlay;

		li {
			padding: 4px 4px;
			border-radius: 4px;
			display: flex;
			align-items: center;

			&:hover {
				background-color: #f2f2f6;
				cursor: pointer;
			}
			&.active {
				color: #409eff;
			}
		}
	}
}
</style>
